<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.restaurants.splashes.title") }}

      <CButton
        color="primary"
        variant="outline"
        square
        size="sm"
        class="float-right"
        :to="{
          name: 'admin.restaurants.splashes.create',
          params: { restaurant: this.$route.params.restaurant }
        }"
      >
        {{ $t("buttons.create") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        border
        striped
        hover
        pagination
      >
        <template #show_days="{item}">
          <td class="py-2">
            {{ formatDaysOfWeek(item.days_of_week) }}
          </td>
        </template>
        <template #show_actions="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              :variant="item.is_hidden ? 'outline' : ''"
              square
              size="sm"
              class="mr-1"
              @click="toggleVisibility(item.id)"
            >
              <CIcon name="cilOpacity" />
            </CButton>

            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              :to="{
                name: 'admin.restaurants.splashes.edit',
                params: {
                  restaurant: $route.params.restaurant,
                  splash: item.id
                }
              }"
            >
              <CIcon name="cilPencil" />
            </CButton>

            <CButton
              color="danger"
              square
              size="sm"
              class="mr-1"
              @click="deleteSplash(item.id)"
            >
              <CIcon name="cilTrash"></CIcon>
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        {
          key: "show_days",
          label: this.$t("admin.restaurants.splashes.days_of_week")
        },
        {
          key: "start_at",
          label: this.$t("admin.restaurants.splashes.start_at")
        },
        {
          key: "finish_at",
          label: this.$t("admin.restaurants.splashes.finish_at")
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 150px"
        }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http
        .get(`/api/admin/restaurants/${this.$route.params.restaurant}/splashes`)
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    },
    formatDaysOfWeek(days) {
      return days.map(day => this.$t(`admin.days_of_week.${day}`)).join(", ");
    },
    toggleVisibility(splash) {
      this.isLoading = true;
      this.$http
        .post(`/api/admin/splashes/${splash}/toggle-visibility`)
        .then(() => this.getData());
    },
    deleteSplash(id) {
      this.isLoading = true;
      this.$http.delete(`/api/admin/splashes/${id}`).then(() => this.getData());
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.restaurants.splashes.title")
    };
  }
};
</script>
