<template>
  <div class="slider-image">
    <p>Sequence: {{ seq }}</p>
    <img class="slider-image-img" :src="storagePath" v-if="path" />
    <span class="delete-button" @click="deleteImage">
      <img alt="" src="/img/icon_close.svg" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    seq: String,
    path: String,
    _class: String
  },

  computed: {
    storagePath() {
      return "/storage/" + this.path;
    }
  },

  methods: {
    deleteImage() {
      this.$emit("delete-image", this.seq);
    }
  }
};
</script>

<style scoped>
.slider-image {
  flex: 1 0 20%;
  margin: 5px;
  height: 200px;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  max-width: 24%;
  padding: 10px;
  position: relative;
}
.slider-image-img {
  max-height: 75%;
  max-width: 90%;
}
.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background: tomato;
  border-radius: 50%;
  padding: 0px 3px;
}
.delete-button img {
  width: 15px;
}
</style>
