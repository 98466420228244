<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{
        $route.meta.edit
          ? $t("admin.restaurants.sections.dishes.edit.title")
          : $t("admin.restaurants.sections.dishes.create.title")
      }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <ValidationError :errors="errors" />
      <CRow>
        <CCol sm="6">
          <CInput :label="$t('admin.name_en')" v-model="form.name.en" />
        </CCol>
        <CCol sm="6">
          <CInput :label="$t('admin.name_fr')" v-model="form.name.fr" />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CInput
            :label="$t('admin.restaurants.sections.dishes.article_id')"
            v-model="form.article_id"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <label>{{ $t("admin.restaurants.description_en") }}</label>
          <CCard bodyWrapper>
            <Editor @updated="setEnglishDescription" ref="description_en" />
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <label>{{ $t("admin.restaurants.description_fr") }}</label>
          <CCard bodyWrapper>
            <Editor @updated="setFrenchDescription" ref="description_fr" />
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <CInput
            v-model="form.stock"
            :label="$t('admin.restaurants.sections.dishes.stock')"
            type="number"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            v-model="form.stock_default"
            :disabled="form.is_stock_resetting === false"
            :label="$t('admin.restaurants.sections.dishes.stock_default')"
            type="number"
          />
        </CCol>
        <CCol sm="3">
          <label>
            {{ $t("admin.restaurants.sections.dishes.is_stock_resetting") }}
          </label>
          <CInputCheckbox
            :checked="form.is_stock_resetting"
            :label="$t('admin.restaurants.sections.dishes.is_stock_resetting')"
            @update:checked="form.is_stock_resetting = $event"
          />
        </CCol>
        <CCol sm="3">
          <label>
            {{ $t("admin.restaurants.sections.dishes.stock_set_at") }}
          </label>
          <p>{{ form.stock_set_at }}</p>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="2">
          <CInput
            :label="$t('admin.price_buy')"
            type="number"
            v-model="form.price_buy"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            :label="$t('admin.price_sell')"
            type="number"
            v-model="form.price_sell"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            :label="$t('admin.previous_price')"
            type="number"
            v-model="form.previous_price"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            :label="$t('admin.restaurants.sections.dishes.per_order')"
            type="number"
            v-model="form.per_order"
          />
        </CCol>
        <CCol sm="3">
          <label>{{ $t("admin.restaurants.sections.dishes.tax") }}</label>
          <Select :options="dish_data.taxes" :value.sync="form.tax" />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="2">
          <label>{{ $t("admin.catalogue.filters.title") }}</label>
          <Checkbox
            :label="filter.name"
            :item="filter"
            :active-items.sync="form.filters"
            v-for="filter in dish_data.filters"
            :key="filter.id"
          />
        </CCol>
        <CCol sm="2">
          <label>{{ $t("admin.restaurants.modifications.title") }}</label>
          <Checkbox
            :label="modification.name"
            :item="modification"
            :active-items.sync="form.modifications"
            v-for="modification in dish_data.modifications"
            :key="modification.id"
          />
        </CCol>
        <CCol sm="3">
          <label>{{ $t("admin.restaurants.toppings.title") }}</label>
          <CRow>
            <CCol sm="6">
              <CInput
                :label="$t('admin.min_toppings')"
                type="number"
                v-model="form.min_toppings"
              />
            </CCol>
            <CCol>
              <CInput
                :label="$t('admin.max_toppings')"
                type="number"
                v-model="form.max_toppings"
              />
            </CCol>
          </CRow>
          <Checkbox
            :label="topping.name"
            :item="topping"
            :active-items.sync="form.toppings"
            v-for="topping in dish_data.toppings"
            :key="topping.id"
          />
        </CCol>
        <CCol sm="3">
          <label>{{ $t("admin.restaurants.toppings_addit.title") }}</label>
          <CRow>
            <CCol sm="6">
              <CInput
                :label="$t('admin.min_toppings')"
                type="number"
                v-model="form.min_toppings_addit"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                :label="$t('admin.max_toppings')"
                type="number"
                v-model="form.max_toppings_addit"
              />
            </CCol>
          </CRow>
          <Checkbox
            :label="topping_addit.name"
            :item="topping_addit"
            :active-items.sync="form.toppings_addit"
            v-for="topping_addit in dish_data.toppings_addit"
            :key="topping_addit.id"
          />
        </CCol>
        <CCol sm="2">
          <label>{{ $t("admin.restaurants.accompaniments.title") }}</label>
          <Checkbox
            :label="accompaniment.name"
            :item="accompaniment"
            :active-items.sync="form.accompaniments"
            v-for="accompaniment in dish_data.accompaniments"
            :key="accompaniment.id"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <label>{{ $t("admin.catalogue.packages.title") }}</label>
          <CInput
            type="number"
            :label="pkg.name"
            v-model="form.packages.find(p => p.id === pkg.id).quantity"
            v-for="pkg in dish_data.packages"
            :key="pkg.id"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CInputFile
            :label="$t('admin.restaurants.image_popup')"
            @change="imagePopupUploaded"
          />
          <StorageImage :path="images.image_popup" _class="w-100" />
        </CCol>
        <CCol sm="6">
          <CInputFile
            :label="$t('admin.restaurants.image_menu')"
            @change="imageMenuUploaded"
          />
          <StorageImage :path="images.image_menu" _class="w-100" />
        </CCol>
      </CRow>
      <CRow class="mt-5">
        <CCol sm="12">
          <CInputFile
            multiple="multiple"
            :label="$t('admin.restaurants.slider_images')"
            @change="sliderImagesUploaded"
          />
          <draggable
            v-model="form.slider_images"
            @end="updateOrder"
            class="slider-images-wrap"
          >
            <StorageImageDraggable
              v-for="slider_image in form.slider_images"
              :key="slider_image.seq"
              :seq="slider_image.seq"
              :path="slider_image.path"
              @delete-image="removeSliderImage"
            />
          </draggable>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Editor from "@/components/admin/Editor";
import Checkbox from "@/components/admin/Checkbox";
import Select from "@/components/admin/Select";
import ValidationError from "@/components/admin/ValidationError";
import StorageImage from "@/components/StorageImage";
import { makeFormData } from "@/helpers";
import draggable from "vuedraggable";
import StorageImageDraggable from "@/components/StorageImageDraggable.vue";

export default {
  components: {
    StorageImageDraggable,
    Editor,
    Checkbox,
    Select,
    ValidationError,
    StorageImage,
    draggable
  },

  data() {
    return {
      isLoading: true,
      errors: [],
      dish_data: {
        accompaniments: [],
        filters: [],
        modifications: [],
        packages: [],
        taxes: [],
        toppings: [],
        toppings_addit: []
      },
      form: {
        name: {},
        description: {},
        price_buy: 0,
        article_id: null,
        price_sell: 0,
        previous_price: "",
        per_order: 1,
        stock: 1000,
        stock_default: 1000,
        is_stock_resetting: true,
        stock_set_at: null,
        tax: null,
        min_toppings: 0,
        max_toppings: 99,
        min_toppings_addit: 0,
        max_toppings_addit: 99,
        accompaniments: [],
        filters: [],
        modifications: [],
        packages: [],
        toppings: [],
        toppings_addit: [],
        slider_images: [],
        slider_images_new: []
      },
      images: {}
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      const requests = [];
      requests.push(
        this.$http.get(
          `/api/admin/restaurants/${this.$route.params.restaurant}/dish-data`
        )
      );
      if (this.$route.meta.edit) {
        requests.push(
          this.$http.get(`/api/admin/dishes/${this.$route.params.dish}`)
        );
      }

      Promise.all(requests).then(responses => {
        const { packages, ...dish_data } = responses[0].data.data;
        this.dish_data = dish_data;

        this.dish_data.taxes = this.dish_data.taxes.map(tax => ({
          value: tax.id,
          label: tax.name
        }));

        if (this.$route.meta.edit) {
          this.form = responses[1].data.data;
          if (this.form.previous_price === null) {
            this.form.previous_price = "";
          }
          this.form.accompaniments = this.form.accompaniments.map(
            accompaniment => accompaniment.id
          );
          this.form.filters = this.form.filters.map(filter => filter.id);
          this.form.modifications = this.form.modifications.map(
            modification => modification.id
          );
          this.form.toppings = this.form.toppings.map(topping => topping.id);
          this.form.toppings_addit = this.form.toppings_addit.map(
            topping_addit => topping_addit.id
          );
          this.form.packages = packages.map(pkg => ({
            id: pkg.id,
            quantity: this.getQuantity(pkg.id)
          }));
          this.form.tax = this.form.tax_id;
          this.$refs.description_en.setContent(this.form.description.en);
          this.$refs.description_fr.setContent(this.form.description.fr);
          this.images.image_popup = this.form.image_popup;
          this.images.image_menu = this.form.image_menu;
          delete this.form.image_popup;
          delete this.form.image_menu;
        } else {
          this.form.packages = packages.map(pkg => ({
            id: pkg.id,
            quantity: 0
          }));
          if (this.dish_data.taxes.length > 0) {
            this.form.tax = this.dish_data.taxes[0].value;
          }
        }

        this.dish_data.packages = packages;

        this.isLoading = false;
      });
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      const url = this.$route.meta.edit
        ? `/api/admin/dishes/${this.$route.params.dish}`
        : `/api/admin/sections/${this.$route.params.section}/dishes`;
      this.$http
        .post(url, makeFormData(this.form, this.$route.meta.edit), {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() =>
          this.$router.push({
            name: "admin.restaurants.sections.dishes",
            params: {
              restaurant: this.$route.params.restaurant,
              section: this.$route.params.section
            }
          })
        )
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    setEnglishDescription(html) {
      this.form.description.en = html;
    },
    setFrenchDescription(html) {
      this.form.description.fr = html;
    },
    imagePopupUploaded(files) {
      this.form.image_popup = files[0];
    },
    imageMenuUploaded(files) {
      this.form.image_menu = files[0];
    },
    sliderImagesUploaded(files) {
      this.form.slider_images_new = files;
    },
    getQuantity(id) {
      const pkg = this.form.packages.find(p => p.id === id);

      return pkg ? pkg.pivot.quantity : 0;
    },
    getImagePath(path) {
      return `/storage/${path}`;
    },
    updateOrder() {
      this.form.slider_images.forEach((image, index) => {
        image.seq = index + 1;
      });
    },
    removeSliderImage(seq) {
      this.form.slider_images = this.form.slider_images.filter(
        image => image.seq !== seq
      );
      this.form.slider_images.forEach((image, index) => {
        image.seq = index + 1;
      });
    }
  },

  metaInfo() {
    return {
      title: this.$route.meta.edit
        ? this.$t("admin.restaurants.sections.dishes.edit.title")
        : this.$t("admin.restaurants.sections.dishes.create.title")
    };
  }
};
</script>

<style scoped>
.slider-images-wrap {
  display: flex;
  flex-wrap: wrap;
}
</style>
