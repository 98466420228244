<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{
        $route.meta.edit
          ? $t("admin.restaurants.timetables.edit.title")
          : $route.meta.pickup
          ? $t("admin.restaurants.timetables.pickup.create.title")
          : $t("admin.restaurants.timetables.delivery.create.title")
      }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
        {{ errors.message }}
        <ul>
          <li v-for="(error, field) in errors.errors" :key="field">
            {{ error[0] }}
          </li>
        </ul>
      </CAlert>
      <CRow>
        <CCol sm="6">
          <label>{{ $t("admin.restaurants.timetables.day_of_week") }}</label>
          <Select :options="days_of_week" :value.sync="form.day_of_week" />
        </CCol>
        <CCol sm="6">
          <label>{{ $t("admin.restaurants.timetables.period") }}</label>
          <Select :options="periods" :value.sync="form.period" />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CInput
            :label="$t('admin.restaurants.timetables.start_at')"
            placeholder="12:00"
            v-model="form.start_at"
          />
        </CCol>
        <CCol sm="6">
          <CInput
            :label="$t('admin.restaurants.timetables.finish_at')"
            placeholder="12:00"
            v-model="form.finish_at"
          />
        </CCol>
      </CRow>
      <CRow v-if="!form.is_pickup">
        <CCol sm="4">
          <CInput
            v-model="form.delivery_fee"
            :label="$t('admin.restaurants.timetables.delivery_fee')"
            type="number"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            v-model="form.min_amount"
            :label="$t('admin.restaurants.timetables.min_amount')"
            type="number"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            v-model="form.delivery_fee_amount"
            :label="$t('admin.restaurants.timetables.delivery_fee_amount')"
            type="number"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Select from "@/components/admin/Select";

export default {
  components: { Select },

  data() {
    return {
      isLoading: true,
      errors: [],
      days_of_week: [],
      periods: [],
      form: {
        day_of_week: null,
        period: null,
        start_at: "",
        finish_at: "",
        delivery_fee: 0,
        min_amount: 0,
        delivery_fee_amount: 0,
        is_pickup: false
      }
    };
  },

  created() {
    this.getData();
    this.form.is_pickup = this.$route.meta.pickup || false;
  },

  methods: {
    getData() {
      this.days_of_week = Object.entries(
        this.$t("admin.days_of_week")
      ).map(([key, value]) => ({ value: parseInt(key), label: value }));

      const requests = [];
      requests.push(this.$http.get("/api/admin/periods"));
      if (this.$route.meta.edit) {
        requests.push(
          this.$http.get(
            `/api/admin/timetables/${this.$route.params.timetable}`
          )
        );
      }

      Promise.all(requests).then(responses => {
        this.periods = responses[0].data.data.map(period => ({
          value: period.id,
          label: period.name
        }));

        if (this.$route.meta.edit) {
          this.form = responses[1].data.data;
          this.form.period = this.form.period_id;
        } else {
          this.form.day_of_week = this.days_of_week[0].value;
          this.form.period = this.periods[0].value;
        }

        this.isLoading = false;
      });
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      const url = this.$route.meta.edit
        ? `/api/admin/timetables/${this.$route.params.timetable}`
        : `/api/admin/restaurants/${this.$route.params.restaurant}/timetables`;
      const method = this.$route.meta.edit ? "put" : "post";
      this.$http[method](url, this.form)
        .then(() =>
          this.$router.push({
            name: this.$route.meta.pickup
              ? "admin.restaurants.timetables.pickup"
              : "admin.restaurants.timetables.delivery",
            params: { restaurant: this.$route.params.restaurant }
          })
        )
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    }
  },

  metaInfo() {
    return {
      title: this.$route.meta.edit
        ? this.$t("admin.restaurants.timetables.edit.title")
        : this.$route.meta.pickup
        ? this.$t("admin.restaurants.timetables.pickup.create.title")
        : this.$t("admin.restaurants.timetables.delivery.create.title")
    };
  }
};
</script>
