<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{
        $route.meta.edit
          ? $t("admin.restaurants.splashes.edit.title")
          : $t("admin.restaurants.splashes.create.title")
      }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
        {{ errors.message }}
        <ul>
          <li v-for="(error, field) in errors.errors" :key="field">
            {{ error[0] }}
          </li>
        </ul>
      </CAlert>
      <CRow>
        <CCol sm="4">
          <label>{{ $t("admin.restaurants.splashes.days_of_week") }}</label>
          <Checkbox
            :label="day_of_week.label"
            :item="day_of_week"
            :active-items.sync="form.days_of_week"
            v-for="day_of_week in days_of_week"
            :key="day_of_week.id"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.splashes.start_at')"
            placeholder="12:00"
            v-model="form.start_at"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.splashes.finish_at')"
            placeholder="12:00"
            v-model="form.finish_at"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CTextarea
            :label="$t('admin.restaurants.splashes.content_en')"
            v-model="form.content.en"
          />
        </CCol>
        <CCol sm="6">
          <CTextarea
            :label="$t('admin.restaurants.splashes.content_fr')"
            v-model="form.content.fr"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Checkbox from "@/components/admin/Checkbox";

export default {
  components: {
    Checkbox
  },

  data() {
    return {
      isLoading: false,
      errors: [],
      days_of_week: [],
      form: {
        days_of_week: [],
        start_at: "",
        finish_at: "",
        content: {}
      }
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.days_of_week = Object.entries(
        this.$t("admin.days_of_week")
      ).map(([key, value]) => ({ id: parseInt(key), label: value }));

      if (this.$route.meta.edit) {
        this.isLoading = true;

        this.$http
          .get(`/api/admin/splashes/${this.$route.params.splash}`)
          .then(response => {
            this.form = response.data.data;
            this.isLoading = false;
          });
      }
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      const url = this.$route.meta.edit
        ? `/api/admin/splashes/${this.$route.params.splash}`
        : `/api/admin/restaurants/${this.$route.params.restaurant}/splashes`;
      const method = this.$route.meta.edit ? "put" : "post";
      this.$http[method](url, this.form)
        .then(() =>
          this.$router.push({
            name: "admin.restaurants.splashes",
            params: { restaurant: this.$route.params.restaurant }
          })
        )
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    }
  },

  metaInfo() {
    return {
      title: this.$route.meta.edit
        ? this.$t("admin.restaurants.splashes.edit.title")
        : this.$t("admin.restaurants.splashes.create.title")
    };
  }
};
</script>
