var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.toppings.title"))+" "),_c('CButton',{staticClass:"float-right",attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
        name: 'admin.restaurants.toppings.create',
        params: { restaurant: _vm.$route.params.restaurant }
      }}},[_vm._v(" "+_vm._s(_vm.$t("buttons.create"))+" ")])],1),_c('CCardBody',[_c('DataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.items,"fields":_vm.fields,"noItemsView":{
        noResults: _vm.$t('admin.no_results'),
        noItems: _vm.$t('admin.no_items')
      },"border":"","striped":"","hover":""},on:{"update:items":_vm.updateOrder},scopedSlots:_vm._u([{key:"show_order_handle",fn:function(){return [_c('td',{staticClass:"py-2 text-center"},[_c('CIcon',{staticClass:"handle",staticStyle:{"transform":"rotate(90deg)","cursor":"pointer"},attrs:{"name":"cilTransfer"}})],1)]},proxy:true},{key:"show_actions",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"primary","square":"","size":"sm","to":{
              name: 'admin.restaurants.toppings.edit',
              params: {
                restaurant: _vm.$route.params.restaurant,
                topping: item.id
              }
            }}},[_c('CIcon',{attrs:{"name":"cilPencil"}})],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }