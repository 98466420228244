var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',{staticClass:"mb-3",attrs:{"align-horizontal":"center"}},[_c('CTabs',{attrs:{"variant":"pills","add-nav-classes":"justify-content-center","activeTab":_vm.activeTab}},_vm._l((_vm.tabs),function(tab){return _c('CTab',{key:tab.name,attrs:{"title":tab.name,"to":tab.route}})}),1)],1),_c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.timetables.title"))+" "),_c('CButton',{staticClass:"float-right",attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
          name: _vm.$route.meta.pickup
            ? 'admin.restaurants.timetables.pickup.create'
            : 'admin.restaurants.timetables.delivery.create',
          params: { restaurant: this.$route.params.restaurant }
        }}},[_vm._v(" "+_vm._s(_vm.$t("buttons.create"))+" ")])],1),_c('CCardBody',[_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.items,"fields":_vm.$route.meta.pickup ? _vm.fields_pickup : _vm.fields,"noItemsView":{
          noResults: _vm.$t('admin.no_results'),
          noItems: _vm.$t('admin.no_items')
        },"border":"","striped":"","hover":"","pagination":""},scopedSlots:_vm._u([{key:"show_day",fn:function({item}){return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.$t(`admin.days_of_week.${item.day_of_week}`))+" ")])]}},{key:"period",fn:function({item}){return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.period.name)+" ")])]}},{key:"show_actions",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"primary","square":"","size":"sm","to":{
                name: 'admin.restaurants.timetables.edit',
                params: {
                  restaurant: _vm.$route.params.restaurant,
                  timetable: item.id
                }
              }}},[_c('CIcon',{attrs:{"name":"cilPencil"}})],1),_c('CButton',{staticClass:"mr-1",attrs:{"color":"danger","square":"","size":"sm"},on:{"click":function($event){return _vm.deleteTimetable(item.id)}}},[_c('CIcon',{attrs:{"name":"cilTrash"}})],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }