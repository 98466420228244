<template>
  <div>
    <CRow align-horizontal="center" class="mb-3">
      <CTabs
        variant="pills"
        add-nav-classes="justify-content-center"
        :activeTab="activeTab"
      >
        <CTab
          :title="tab.name"
          :to="tab.route"
          v-for="tab in tabs"
          :key="tab.name"
        />
      </CTabs>
    </CRow>

    <CCard>
      <CCardHeader>
        {{ $t("admin.restaurants.timetables.title") }}

        <CButton
          color="primary"
          variant="outline"
          square
          size="sm"
          class="float-right"
          :to="{
            name: $route.meta.pickup
              ? 'admin.restaurants.timetables.pickup.create'
              : 'admin.restaurants.timetables.delivery.create',
            params: { restaurant: this.$route.params.restaurant }
          }"
        >
          {{ $t("buttons.create") }}
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :loading="isLoading"
          :items="items"
          :fields="$route.meta.pickup ? fields_pickup : fields"
          :noItemsView="{
            noResults: $t('admin.no_results'),
            noItems: $t('admin.no_items')
          }"
          border
          striped
          hover
          pagination
        >
          <template #show_day="{item}">
            <td class="py-2">
              {{ $t(`admin.days_of_week.${item.day_of_week}`) }}
            </td>
          </template>
          <template #period="{item}">
            <td class="py-2">
              {{ item.period.name }}
            </td>
          </template>
          <template #show_actions="{item}">
            <td class="py-2 text-center">
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                :to="{
                  name: 'admin.restaurants.timetables.edit',
                  params: {
                    restaurant: $route.params.restaurant,
                    timetable: item.id
                  }
                }"
              >
                <CIcon name="cilPencil" />
              </CButton>
              <CButton
                color="danger"
                square
                size="sm"
                class="mr-1"
                @click="deleteTimetable(item.id)"
              >
                <CIcon name="cilTrash"></CIcon>
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        {
          key: "show_day",
          label: this.$t("admin.restaurants.timetables.day_of_week")
        },
        {
          key: "period",
          label: this.$t("admin.restaurants.timetables.period")
        },
        {
          key: "start_at",
          label: this.$t("admin.restaurants.timetables.start_at")
        },
        {
          key: "finish_at",
          label: this.$t("admin.restaurants.timetables.finish_at")
        },
        {
          key: "delivery_fee",
          label: this.$t("admin.restaurants.timetables.delivery_fee")
        },
        {
          key: "min_amount",
          label: this.$t("admin.restaurants.timetables.min_amount")
        },
        {
          key: "delivery_fee_amount",
          label: this.$t("admin.restaurants.timetables.delivery_fee_amount")
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 130px"
        }
      ],
      fields_pickup: [
        { key: "id", label: "ID", _style: "width: 100px" },
        {
          key: "show_day",
          label: this.$t("admin.restaurants.timetables.day_of_week")
        },
        {
          key: "period",
          label: this.$t("admin.restaurants.timetables.period")
        },
        {
          key: "start_at",
          label: this.$t("admin.restaurants.timetables.start_at")
        },
        {
          key: "finish_at",
          label: this.$t("admin.restaurants.timetables.finish_at")
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 130px"
        }
      ],
      tabs: [
        {
          route: { name: "admin.restaurants.timetables.delivery" },
          name: this.$t("admin.delivery")
        },
        {
          route: { name: "admin.restaurants.timetables.pickup" },
          name: this.$t("admin.pickup")
        }
      ]
    };
  },

  computed: {
    activeTab() {
      return this.tabs.findIndex(tab => tab.route.name === this.$route.name);
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getData();
      }
    }
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http
        .get(
          `/api/admin/restaurants/${this.$route.params.restaurant}/timetables`,
          {
            params: {
              is_pickup: this.$route.meta.pickup || false
            }
          }
        )
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    },
    deleteTimetable(id) {
      this.isLoading = true;
      this.$http
        .delete(`/api/admin/timetables/${id}`)
        .then(() => this.getData());
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.restaurants.timetables.title")
    };
  }
};
</script>
