<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{
        $route.meta.edit
          ? $t("admin.restaurants.edit.title")
          : $t("admin.restaurants.create.title")
      }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <ValidationError :errors="errors" />
      <CRow>
        <CCol sm="6">
          <CInput :label="$t('admin.name_en')" v-model="form.name.en" />
        </CCol>
        <CCol sm="6">
          <CInput :label="$t('admin.name_fr')" v-model="form.name.fr" />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CInput
            :label="$t('admin.restaurants.address')"
            v-model="form.address"
          />
        </CCol>
        <CCol sm="6">
          <CRow>
            <CCol sm="6">
              <CInput
                :label="$t('admin.restaurants.lat')"
                type="number"
                v-model="form.lat"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                :label="$t('admin.restaurants.lng')"
                type="number"
                v-model="form.lng"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.phone')"
            type="tel"
            v-model="form.phone"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            :label="$t('admin.restaurants.discount')"
            type="number"
            v-model="form.discount"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            v-model="form.pickup_discount"
            :label="$t('admin.restaurants.pickup_discount')"
            type="number"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            :label="$t('admin.restaurants.orders_limit_delivery')"
            type="number"
            v-model="form.orders_limit_delivery"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            :label="$t('admin.restaurants.orders_limit_pickup')"
            type="number"
            v-model="form.orders_limit_pickup"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="2">
          <CInput
            :label="$t('admin.restaurants.delivery_time')"
            type="number"
            v-model="form.delivery_time"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            :label="$t('admin.restaurants.cooking_time')"
            type="number"
            v-model="form.cooking_time"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            :label="$t('admin.restaurants.pickup_cooking_time')"
            type="number"
            v-model="form.pickup_cooking_time"
          />
        </CCol>
        <CCol sm="2">
          <CInput
            :label="$t('admin.restaurants.pickup_offset')"
            type="number"
            v-model="form.pickup_offset"
          />
        </CCol>
        <CCol sm="2">
          <label>{{ $t("admin.restaurants.standalone_intervals") }}</label>
          <CInputCheckbox
            :checked="form.is_standalone"
            :label="$t('admin.restaurants.is_standalone')"
            @update:checked="form.is_standalone = $event"
          />
        </CCol>
        <CCol sm="2">
          <label>{{ $t("admin.restaurants.pickup_eligibility") }}</label>
          <CInputCheckbox
            :checked="form.is_pickup_eligible"
            :label="$t('admin.restaurants.is_pickup_eligible')"
            @update:checked="form.is_pickup_eligible = $event"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInputCheckbox
            :checked="form.is_new"
            :label="$t('admin.restaurants.is_new')"
            @update:checked="form.is_new = $event"
          /><br />
          <CInputCheckbox
            :checked="form.is_allergen"
            :label="
              $t('admin.restaurants.is_allergen') + '(' + images.pdf_file + ')'
            "
            @update:checked="form.is_allergen = $event"
          /><br />
          <CInputFile
            :label="$t('admin.restaurants.pdf_file')"
            @change="pdfUploaded"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <label>{{ $t("admin.restaurants.description_en") }}</label>
          <CCard bodyWrapper>
            <Editor @updated="setDescriptionEn" ref="description_en" />
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <label>{{ $t("admin.restaurants.description_fr") }}</label>
          <CCard bodyWrapper>
            <Editor @updated="setDescriptionFr" ref="description_fr" />
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <CInput
            v-model="form.toppings_title.en"
            :label="$t('admin.restaurants.toppings_title_en')"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            v-model="form.toppings_addit_title.en"
            :label="$t('admin.restaurants.toppings_addit_title_en')"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            v-model="form.modifications_title.en"
            :label="$t('admin.restaurants.modifications_title_en')"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            v-model="form.accompaniments_title.en"
            :label="$t('admin.restaurants.accompaniments_title_en')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <CInput
            v-model="form.toppings_title.fr"
            :label="$t('admin.restaurants.toppings_title_fr')"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            v-model="form.toppings_addit_title.fr"
            :label="$t('admin.restaurants.toppings_addit_title_fr')"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            v-model="form.modifications_title.fr"
            :label="$t('admin.restaurants.modifications_title_fr')"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            v-model="form.accompaniments_title.fr"
            :label="$t('admin.restaurants.accompaniments_title_fr')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.seo_keywords_en')"
            v-model="form.seo_keywords.en"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.seo_title_en')"
            v-model="form.seo_title.en"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.seo_alt_en')"
            v-model="form.seo_alt.en"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.seo_keywords_fr')"
            v-model="form.seo_keywords.fr"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.seo_title_fr')"
            v-model="form.seo_title.fr"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.seo_alt_fr')"
            v-model="form.seo_alt.fr"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.manager.name')"
            v-model="form.manager.name"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.restaurants.manager.password')"
            type="password"
            v-model="form.manager.password"
          />
        </CCol>
        <CCol sm="4">
          <label>{{ $t("admin.restaurants.forward_to") }}</label>
          <Select
            :options="forward_to_restaurants"
            :value.sync="form.forward_to"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <CInput
            :label="$t('admin.restaurants.booking_url')"
            v-model="form.booking_url"
          />
        </CCol>
        <CCol sm="3">
          <label>{{ $t("admin.restaurants.kitchen_type") }}</label>
          <Select :options="kitchen_types" :value.sync="form.kitchen_type" />
        </CCol>
        <CCol sm="3">
          <label>{{ $t("admin.restaurants.type") }}</label>
          <Select :options="types" :value.sync="form.type_id" />
        </CCol>
        <CCol sm="3">
          <label>{{ $t("admin.restaurants.cuisine") }}</label>
          <Checkbox
            :label="cuisine.name"
            :item="cuisine"
            :active-items.sync="form.cuisines"
            v-for="cuisine in cuisines"
            :key="cuisine.id"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <CInputFile
            :label="$t('admin.restaurants.image_main')"
            @change="imageMainUploaded"
          />
          <StorageImage :path="images.image_main" _class="w-100" />
        </CCol>
        <CCol sm="3">
          <CInputFile
            :label="$t('admin.restaurants.image_big')"
            @change="imageBigUploaded"
          />
          <StorageImage :path="images.image_big" _class="w-100" />
        </CCol>
        <CCol sm="3">
          <CInputFile
            :label="$t('admin.restaurants.image_top')"
            @change="imageTopUploaded"
          />
          <StorageImage :path="images.image_top" _class="w-100" />
        </CCol>
        <CCol sm="3">
          <CInputFile
            :label="$t('admin.restaurants.image_logo')"
            @change="imageLogoUploaded"
          />
          <StorageImage :path="images.image_logo" _class="w-100" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Checkbox from "@/components/admin/Checkbox";
import Editor from "@/components/admin/Editor";
import Select from "@/components/admin/Select";
import ValidationError from "@/components/admin/ValidationError";
import StorageImage from "@/components/StorageImage";
import { makeFormData } from "@/helpers";

export default {
  components: {
    Checkbox,
    Editor,
    Select,
    ValidationError,
    StorageImage
  },

  data() {
    return {
      isLoading: true,
      errors: [],
      cuisines: [],
      types: [],
      forward_to_restaurants: [],
      kitchen_types: [
        { value: null, label: "Both" },
        { value: "simple", label: "Simple" },
        { value: "dark", label: "Dark" }
      ],
      form: {
        name: {},
        address: "",
        lat: 0,
        lng: 0,
        phone: "",
        discount: 0,
        pickup_discount: 0,
        delivery_time: 0,
        cooking_time: 0,
        pickup_cooking_time: 0,
        pickup_offset: 0,
        is_pickup_eligible: false,
        is_new: false,
        is_allergen: false,
        is_standalone: false,
        description: {},
        seo_keywords: {},
        toppings_title: {
          en: "Toppings",
          fr: "Toppings"
        },
        toppings_addit_title: {
          en: "Toppings additional",
          fr: "Toppings additional"
        },
        modifications_title: {
          en: "Modifications",
          fr: "Modifications"
        },
        accompaniments_title: {
          en: "Accompaniments",
          fr: "Accompaniments"
        },
        seo_title: {},
        seo_alt: {},
        manager: {},
        kitchen_type: null,
        booking_url: null,
        cuisines: [],
        type_id: null,
        forward_to: null,
        orders_limit_delivery: 0,
        orders_limit_pickup: 0
      },
      images: {}
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      const requests = [];
      requests.push(this.$http.get("/api/admin/cuisines"));
      requests.push(this.$http.get("/api/admin/types"));
      requests.push(this.$http.get("/api/management/restaurants"));
      if (this.$route.meta.edit) {
        requests.push(
          this.$http.get(
            `/api/admin/restaurants/${this.$route.params.restaurant}`
          )
        );
      }

      Promise.all(requests).then(responses => {
        this.cuisines = responses[0].data.data;
        this.types = [
          { value: null, label: "None" },
          ...responses[1].data.data.map(type => ({
            value: type.id,
            label: type.name
          }))
        ];
        this.forward_to_restaurants = [
          { value: null, label: "Current" },
          ...responses[2].data.data.map(restaurant => ({
            value: restaurant.id,
            label: restaurant.name
          }))
        ];
        if (this.$route.meta.edit) {
          this.form = responses[3].data.data;
          this.form.cuisines = this.form.cuisines.map(cuisine => cuisine.id);
          this.$refs.description_en.setContent(this.form.description.en);
          this.$refs.description_fr.setContent(this.form.description.fr);
          this.images.image_main = this.form.image_main;
          this.images.image_big = this.form.image_big;
          this.images.image_top = this.form.image_top;
          this.images.image_logo = this.form.image_logo;
          this.images.pdf_file = this.form.pdf_file;
          delete this.form.image_main;
          delete this.form.image_big;
          delete this.form.image_top;
          delete this.form.image_logo;
          delete this.form.pdf_file;
          this.forward_to_restaurants = this.forward_to_restaurants.filter(
            option => option.value !== this.form.id
          );
        }

        this.isLoading = false;
      });
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      const url = this.$route.meta.edit
        ? `/api/admin/restaurants/${this.$route.params.restaurant}`
        : "/api/admin/restaurants";
      this.$http
        .post(url, makeFormData(this.form, this.$route.meta.edit), {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() => this.$router.push({ name: "admin.restaurants" }))
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    setDescriptionEn(html) {
      this.form.description.en = html;
    },
    setDescriptionFr(html) {
      this.form.description.fr = html;
    },
    imageMainUploaded(files) {
      this.form.image_main = files[0];
    },
    imageBigUploaded(files) {
      this.form.image_big = files[0];
    },
    pdfUploaded(files) {
      this.form.pdf_file = files[0];
    },
    imageTopUploaded(files) {
      this.form.image_top = files[0];
    },
    imageLogoUploaded(files) {
      this.form.image_logo = files[0];
    }
  },

  metaInfo() {
    return {
      title: this.$route.meta.edit
        ? this.$t("admin.restaurants.edit.title")
        : this.$t("admin.restaurants.create.title")
    };
  }
};
</script>
