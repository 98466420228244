var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.title"))+" "),_c('CButton',{staticClass:"float-right",attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{ name: 'admin.restaurants.create' }}},[_vm._v(" "+_vm._s(_vm.$t("buttons.create"))+" ")])],1),_c('CCardBody',[_c('DataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.items,"fields":_vm.fields,"noItemsView":{
        noResults: _vm.$t('admin.no_results'),
        noItems: _vm.$t('admin.no_items')
      },"border":"","striped":"","hover":""},on:{"update:items":_vm.updateOrder},scopedSlots:_vm._u([{key:"show_timetable",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.timetables',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_toppings",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.toppings',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_toppings_addit",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.toppings_addit',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_modifications",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.modifications',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_accompaniments",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.accompaniments',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_sections",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.sections',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_sliders",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.sliders',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_splashes",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.splashes',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_deleted",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.restaurants.deleted_items',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_order_handle",fn:function(){return [_c('td',{staticClass:"py-2 text-center"},[_c('CIcon',{staticClass:"handle",staticStyle:{"transform":"rotate(90deg)","cursor":"pointer"},attrs:{"name":"cilTransfer"}})],1)]},proxy:true},{key:"show_actions",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"primary","variant":item.is_hidden ? 'outline' : '',"square":"","size":"sm"},on:{"click":function($event){return _vm.toggleVisibility(item.id)}}},[_c('CIcon',{attrs:{"name":"cilOpacity"}})],1),_c('CButton',{staticClass:"mr-1",attrs:{"color":"primary","square":"","size":"sm","to":{
              name: 'admin.restaurants.edit',
              params: { restaurant: item.id }
            }}},[_c('CIcon',{attrs:{"name":"cilPencil"}})],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }