<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.restaurants.sections.title") }}

      <CButton
        color="primary"
        variant="outline"
        square
        size="sm"
        class="float-right"
        :to="{
          name: 'admin.restaurants.sections.create',
          params: { id: $route.params.restaurant }
        }"
      >
        {{ $t("buttons.create") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <DataTable
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        @update:items="updateOrder"
        border
        striped
        hover
      >
        <template v-slot:[period.name]="{ item }" v-for="period in periods">
          <td class="py-2 text-center" :key="`${item.id}_period_${period.id}`">
            <CButton
              color="primary"
              :variant="
                item.periods.find(p => p.id === period.id) ? '' : 'outline'
              "
              square
              size="sm"
              class="mr-1"
              @click="togglePeriod(item.id, period.id)"
            >
              <CIcon name="cilOpacity" />
            </CButton>
          </td>
        </template>

        <template #show_dishes="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              :to="{
                name: 'admin.restaurants.sections.dishes',
                params: {
                  restaurant: $route.params.restaurant,
                  section: item.id
                }
              }"
            >
              {{ $t("admin.restaurants.go") }}
            </CButton>
          </td>
        </template>

        <template #show_order_handle>
          <td class="py-2 text-center">
            <CIcon
              name="cilTransfer"
              class="handle"
              style="transform: rotate(90deg); cursor: pointer"
            ></CIcon>
          </td>
        </template>

        <template #show_actions="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              :variant="item.is_hidden ? 'outline' : ''"
              square
              size="sm"
              class="mr-1"
              @click="toggleVisibility(item.id)"
            >
              <CIcon name="cilOpacity" />
            </CButton>

            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              :to="{
                name: 'admin.restaurants.sections.edit',
                params: {
                  restaurant: $route.params.restaurant,
                  section: item.id
                }
              }"
            >
              <CIcon name="cilPencil" />
            </CButton>
          </td>
        </template>
      </DataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },

  data() {
    return {
      isLoading: true,
      periods: [],
      periodsFields: [],
      items: []
    };
  },

  created() {
    this.getData();
  },

  computed: {
    fields() {
      return [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "name", label: this.$t("admin.name") },
        { key: "show_order_handle", label: this.$t("admin.order") },
        ...this.periodsFields,
        {
          key: "show_dishes",
          label: this.$t("admin.restaurants.sections.dishes.title"),
          _style: "width: 100px"
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 130px"
        }
      ];
    }
  },

  methods: {
    getData() {
      const requests = [
        this.$http.get("/api/admin/periods"),
        this.$http.get(
          `/api/admin/restaurants/${this.$route.params.restaurant}/sections`
        )
      ];

      Promise.all(requests).then(responses => {
        this.periods = responses[0].data.data;
        this.periodsFields = this.periods.map(period => ({
          key: period.name,
          label: period.name,
          _style: "width: 100px"
        }));
        this.items = responses[1].data.data;
        this.isLoading = false;
      });
    },
    togglePeriod(section, period) {
      this.isLoading = true;
      this.$http
        .post(`/api/admin/sections/${section}/toggle-period`, { period })
        .then(() => this.getData());
    },
    toggleVisibility(section) {
      this.isLoading = true;
      this.$http
        .post(`/api/admin/sections/${section}/toggle-visibility`)
        .then(() => this.getData());
    },
    updateOrder(items) {
      this.isLoading = true;
      this.$http
        .put(`/api/admin/sections/update-order`, {
          ids: items.map(item => item.id)
        })
        .then(() => this.getData());
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.restaurants.sections.title")
    };
  }
};
</script>
